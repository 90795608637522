.datasecurity-container{
    display: block;
    margin: auto;
    height: 80vh;
    width: 60%;
    color: white;
    text-shadow: 1px 1px black;
    overflow-y: scroll;
    padding-right: 10px;
}

.source-link{
    color: white;
}

/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #00000000; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(126, 126, 126); 
}

/*Mobile scaling*/

@media screen and (max-width: 960px) {
    .datasecurity-container{
        margin-left: 8%;
        width: 85%;
    }
}