.home_container{
  color: white;
  display: flex;
  flex-wrap: wrap;
  text-shadow: 1px 1px black;
}

.left_home{
  width: 50%;
  float: left;
  flex: auto;
}
  
.person{
  border-radius: 35%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 50px;
}
  
.interests{
  text-align: center;
}
  
.interest_list{
  display: grid;
  grid-template-columns: 30% 30%;
  margin-left: 25%;
}

.interest_bulletpoint{
  display: flex;
  align-items: center;
  font-size: larger;
  margin: 5px;
}

.interest_icon{
  width: 40px;
  height: 40px;
  filter: invert(100%);
  padding: 10px;
}

.right_home{
  width: 50%;
  float: right; 
}
  
.text_container{
	text-align: justify;
  font-size: large;
  margin-top: 7%;
  margin-left: 5%;
  margin-right: 25%;
}

.text_break{
  margin: 10px 0px;
}
  
.headlines{
  text-align: center;
}

@media screen and (max-width: 960px) {

  .home_container{
    display: grid;
  }

  .left_home{
    width: auto;
  }

  .person{
    margin-top: 20px;
  }

  .right_home{
    width: auto;
  }

  .text_container{
    margin-right: 5%;
  }

  .headlines{
    margin: 50px 0px 25px;
  }

  .interests{
    font-size: larger;
  }

  .interest_list{
    grid-template-columns: 100%;
    margin: 5%;
  }

}