.project-box{
    text-shadow: black 1px 1px;
    border-radius: 25px;
    box-shadow: 2px 2px 5px 1px #0c0c0c;
    position: relative;
    border: 2px black solid;
    background-position: center;
    background-size: cover;
    transition: 0.3s ease;
}
  
.project-box:hover{
    filter: brightness(0.5);
}

.project-description{
    margin: 0 5%;
    text-align: justify;
}
  
.project-more-info{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    color: white;
    width: 100%;
    height: 100%;
}
  
.project-instruction{
    margin: 10px;
    position: relative;
    text-align: right;
}
  
.project-title{
    text-align: center;
}

@media screen and (max-width: 960px) {

}
