.navbar{
  position: relative;
  z-index: 999;
  display:flex;
  min-height: 10vh;
}
  
.navbar-container{
  flex-basis: 100%;
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  flex-direction: row;
}

.menu-icon{
  display: none;
}

.nav-menu{
  margin-left: auto;
  margin-top: 30px;
  order: 1;
  display: inline-flex;
  align-items: center;
}

.logo{
  color: white;
  font-size: 40px;
  text-decoration: none;
}

.logo:hover{
  transition: ease 0.2s;
  color: rgb(153, 153, 153);
}

.nav-item{
  margin: 10px 5px;
  list-style-type: none;
}

.nav-links{
  border-radius: 25px;
  color: white;
  padding: 10px;
}

.nav-links:hover{
  transition: ease-in 0.2s;
  background-color: rgb(255, 255, 255);
  color: black;
}

.media_link{
  color: white;
  display: inline-block;
}

.icon{
  filter: invert(100%);
  width: 40px;
  height: 40px;
  padding: 10px;
  display: block;
}

.icon:hover{
  transition: ease 0.2s;
  filter: invert(60%);
}

@media screen and (max-width: 960px) {

  .navbar-container{
    padding: 10px 0px;
    position: relative;
    justify-content: center;
  }

  .menu-icon{
    display: flex;
    position: fixed;
    background-color: #0000004a;
    bottom: 50%;
    left: 0;
    width: 16px;
    height: 40px;
    z-index: 999;
    transition: all 0.5s ease;
    align-items: center;
    justify-content: center;
  }

  .menu-icon.active{
    transition: all 0.5s ease;
  }

  .mobile-icon{
    filter: invert(100%);
    width: 25px;
    height: 25px;
  }

  .nav-menu {
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    left: -110%;
    top: 0px;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #53046d;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding: 0px;
  }

  .icon{
    width: 30px;
    height: 30px;
  }

  .nav-item {
    width: 100%;
    margin: 0px;
  }

  .nav-links{
    width: 100%;
    font-size: larger;
    padding: 10px 0px;
    display: inline-block;
    text-align: center;
  }

  .nav-item-mobile{
    list-style-type: none;
    text-align: center;
    margin-top: 30px;
    padding: 20px;
  }

  .media_link{
    display: inline-block;
  }
}