input{
    width: -webkit-fill-available;
    margin: 5px;
}
  
.contact-container{
    margin: 0% 5%;
    color: white;
    text-shadow: 1px 1px black;
    align-content: center;
    display: grid;
    grid-template-columns: 50% 50%;
}
  
.contact-left-side{
    text-align: justify;
    font-size: larger;
    margin: 50px auto;
    width: 50%;
}

.right-container{
    width: 80%;
}
  
.contact-right-side{
    margin: 50px auto;
    display: grid;
    grid-template-columns: 50% 50%;
}

.contact-right-side * {
    font-size: large;
}
  
.regard{
    grid-column: 1 / 3;
}
  
.message{
    grid-column: 1 / 3;
    margin: 5px;
}

.input{
    padding: 10px;
    border: none;
    border-radius: 25px;
}

.contact-datasecurity{
    margin: 5px;
    cursor: pointer;
    grid-column: 1 / 3;
}

.datasecurity-link{
    color: white;
}

.datasecurity-link:hover{
    color: gray;
}

.contact-submit{
    border-radius: 25px;
    color: white;
    text-shadow: 1px 1px black;
    padding: 10px 5px;
    border: black 2px;
    border-style: solid;
    background: transparent;
    grid-column: 1 / 3;
    width: 50%;
    margin-top: 10px;
    justify-self: end;
}

.contact-submit:hover{
    transition: ease 0.2s;
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
  
@media screen and (max-width: 960px) {

    .contact-container{
        display: flex;
        flex-direction: column;
    }

    .right-container{
        width: 100%;
    }

    .contact-right-side{
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .contact-left-side{
        font-size: large;
        width: fit-content;
        margin: 0px 0px;
    }

    .contact-submit{
        align-self: center;
        margin-top: 20px;
    }
}