.projects-container{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: repeat(5, minmax(200px, auto));
    row-gap: 20px;
    column-gap: 30px;
    width: 70%;
    margin:20px auto;
    overflow: hidden;
    padding: 5px;
}

.project-box{
    height: auto;
}

@media screen and (max-width: 960px) {

    .projects-container{
        position: relative;
        z-index: 0;
        grid-template-columns: minmax(200px, auto);
    }

    .project-box{
        height: auto;
    }
}