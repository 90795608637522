.impressum-container{
    display: block;
    margin: auto;
    height: 80vh;
    width: 60%;
    color: white;
    text-shadow: 1px 1px black;
}

/*Mobile scaling*/

@media screen and (max-width: 960px) {
    .impressum-container{
        width: 90%;
        margin-left: 7%;
    }
}