.footer-container{
    background: linear-gradient(45deg, #69203f, #0d0d0d4d);
    min-height: 50px;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    color: white;
    margin-top: 30px;
}

.freepik{
    position: relative;
    font-size: smaller;
}

.footer-links{
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}

.text-links{
  color: white;
  font-weight: bold;
}

.text-links:hover{
  transition: ease 0.2s;
  color: rgb(153, 153, 153);
}

@media screen and (max-width: 960px) {

  .footer-container{
    position: relative;
  }

  .footer-links{
    margin-bottom: 10px;
  }
}
