body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*All*/

@font-face {
  font-family: "DaysOne-Regular";
  src: url(/static/media/OpenSans-Regular.1b0809d5.ttf);
}

body{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

#root{
  background-image: 
    linear-gradient(to bottom, rgb(255 255 255 / 0%), rgb(35 35 35 / 30%)),
    url(/static/media/back.4dfbe57d.jpg); 
  background-size: cover;
  height: 100%;
}

* {
  font-family: "DaysOne-Regular", Fallback, sans-serif;
  text-decoration: none;
}

/*Mobile scaling*/

@media screen and (max-width: 960px) {

  /*Overall*/

  body{
    height: 100%;
    overflow: visible;
  }

}
.navbar{
  position: relative;
  z-index: 999;
  display:flex;
  min-height: 10vh;
}
  
.navbar-container{
  flex-basis: 100%;
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  flex-direction: row;
}

.menu-icon{
  display: none;
}

.nav-menu{
  margin-left: auto;
  margin-top: 30px;
  order: 1;
  display: inline-flex;
  align-items: center;
}

.logo{
  color: white;
  font-size: 40px;
  text-decoration: none;
}

.logo:hover{
  transition: ease 0.2s;
  color: rgb(153, 153, 153);
}

.nav-item{
  margin: 10px 5px;
  list-style-type: none;
}

.nav-links{
  border-radius: 25px;
  color: white;
  padding: 10px;
}

.nav-links:hover{
  transition: ease-in 0.2s;
  background-color: rgb(255, 255, 255);
  color: black;
}

.media_link{
  color: white;
  display: inline-block;
}

.icon{
  -webkit-filter: invert(100%);
          filter: invert(100%);
  width: 40px;
  height: 40px;
  padding: 10px;
  display: block;
}

.icon:hover{
  transition: ease 0.2s;
  -webkit-filter: invert(60%);
          filter: invert(60%);
}

@media screen and (max-width: 960px) {

  .navbar-container{
    padding: 10px 0px;
    position: relative;
    justify-content: center;
  }

  .menu-icon{
    display: flex;
    position: fixed;
    background-color: #0000004a;
    bottom: 50%;
    left: 0;
    width: 16px;
    height: 40px;
    z-index: 999;
    transition: all 0.5s ease;
    align-items: center;
    justify-content: center;
  }

  .menu-icon.active{
    transition: all 0.5s ease;
  }

  .mobile-icon{
    -webkit-filter: invert(100%);
            filter: invert(100%);
    width: 25px;
    height: 25px;
  }

  .nav-menu {
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    left: -110%;
    top: 0px;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #53046d;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding: 0px;
  }

  .icon{
    width: 30px;
    height: 30px;
  }

  .nav-item {
    width: 100%;
    margin: 0px;
  }

  .nav-links{
    width: 100%;
    font-size: larger;
    padding: 10px 0px;
    display: inline-block;
    text-align: center;
  }

  .nav-item-mobile{
    list-style-type: none;
    text-align: center;
    margin-top: 30px;
    padding: 20px;
  }

  .media_link{
    display: inline-block;
  }
}
.footer-container{
    background: linear-gradient(45deg, #69203f, #0d0d0d4d);
    min-height: 50px;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    color: white;
    margin-top: 30px;
}

.freepik{
    position: relative;
    font-size: smaller;
}

.footer-links{
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}

.text-links{
  color: white;
  font-weight: bold;
}

.text-links:hover{
  transition: ease 0.2s;
  color: rgb(153, 153, 153);
}

@media screen and (max-width: 960px) {

  .footer-container{
    position: relative;
  }

  .footer-links{
    margin-bottom: 10px;
  }
}

.home_container{
  color: white;
  display: flex;
  flex-wrap: wrap;
  text-shadow: 1px 1px black;
}

.left_home{
  width: 50%;
  float: left;
  flex: auto;
}
  
.person{
  border-radius: 35%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 50px;
}
  
.interests{
  text-align: center;
}
  
.interest_list{
  display: grid;
  grid-template-columns: 30% 30%;
  margin-left: 25%;
}

.interest_bulletpoint{
  display: flex;
  align-items: center;
  font-size: larger;
  margin: 5px;
}

.interest_icon{
  width: 40px;
  height: 40px;
  -webkit-filter: invert(100%);
          filter: invert(100%);
  padding: 10px;
}

.right_home{
  width: 50%;
  float: right; 
}
  
.text_container{
	text-align: justify;
  font-size: large;
  margin-top: 7%;
  margin-left: 5%;
  margin-right: 25%;
}

.text_break{
  margin: 10px 0px;
}
  
.headlines{
  text-align: center;
}

@media screen and (max-width: 960px) {

  .home_container{
    display: grid;
  }

  .left_home{
    width: auto;
  }

  .person{
    margin-top: 20px;
  }

  .right_home{
    width: auto;
  }

  .text_container{
    margin-right: 5%;
  }

  .headlines{
    margin: 50px 0px 25px;
  }

  .interests{
    font-size: larger;
  }

  .interest_list{
    grid-template-columns: 100%;
    margin: 5%;
  }

}
input{
    width: -webkit-fill-available;
    margin: 5px;
}
  
.contact-container{
    margin: 0% 5%;
    color: white;
    text-shadow: 1px 1px black;
    align-content: center;
    display: grid;
    grid-template-columns: 50% 50%;
}
  
.contact-left-side{
    text-align: justify;
    font-size: larger;
    margin: 50px auto;
    width: 50%;
}

.right-container{
    width: 80%;
}
  
.contact-right-side{
    margin: 50px auto;
    display: grid;
    grid-template-columns: 50% 50%;
}

.contact-right-side * {
    font-size: large;
}
  
.regard{
    grid-column: 1 / 3;
}
  
.message{
    grid-column: 1 / 3;
    margin: 5px;
}

.input{
    padding: 10px;
    border: none;
    border-radius: 25px;
}

.contact-datasecurity{
    margin: 5px;
    cursor: pointer;
    grid-column: 1 / 3;
}

.datasecurity-link{
    color: white;
}

.datasecurity-link:hover{
    color: gray;
}

.contact-submit{
    border-radius: 25px;
    color: white;
    text-shadow: 1px 1px black;
    padding: 10px 5px;
    border: black 2px;
    border-style: solid;
    background: transparent;
    grid-column: 1 / 3;
    width: 50%;
    margin-top: 10px;
    justify-self: end;
}

.contact-submit:hover{
    transition: ease 0.2s;
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
  
@media screen and (max-width: 960px) {

    .contact-container{
        display: flex;
        flex-direction: column;
    }

    .right-container{
        width: 100%;
    }

    .contact-right-side{
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .contact-left-side{
        font-size: large;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0px 0px;
    }

    .contact-submit{
        align-self: center;
        margin-top: 20px;
    }
}
@keyframes blend-in {
  0.0%{
      bottom: -110%;
  }
  99.5%{
      bottom: 50px;
  }
}

.infobox{
  border-radius: 10px;
  position: absolute;
  bottom: 50px;
  right: 0;
  animation: blend-in .5s ease-out 0s 1 normal none;
}

.infomessage{
  margin: 25px;
  color: white;
}

.infobutton{
  position: absolute;
  top: 5px;
  left: 5px;
  width: auto;
  height: auto;
}

.infobutton *{
  -webkit-filter: invert(100%);
          filter: invert(100%);
  width: 15px;
  height: 15px;
}

.infobutton:hover{
  transition: ease 0.2s;
  -webkit-filter: invert(60%);
          filter: invert(60%);
  cursor: pointer;
}

@media screen and (max-width: 960px) {

  }
.project-box{
    text-shadow: black 1px 1px;
    border-radius: 25px;
    box-shadow: 2px 2px 5px 1px #0c0c0c;
    position: relative;
    border: 2px black solid;
    background-position: center;
    background-size: cover;
    transition: 0.3s ease;
}
  
.project-box:hover{
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
}

.project-description{
    margin: 0 5%;
    text-align: justify;
}
  
.project-more-info{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    color: white;
    width: 100%;
    height: 100%;
}
  
.project-instruction{
    margin: 10px;
    position: relative;
    text-align: right;
}
  
.project-title{
    text-align: center;
}

@media screen and (max-width: 960px) {

}

.projects-container{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: repeat(5, minmax(200px, auto));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    width: 70%;
    margin:20px auto;
    overflow: hidden;
    padding: 5px;
}

.project-box{
    height: auto;
}

@media screen and (max-width: 960px) {

    .projects-container{
        position: relative;
        z-index: 0;
        grid-template-columns: minmax(200px, auto);
    }

    .project-box{
        height: auto;
    }
}
.impressum-container{
    display: block;
    margin: auto;
    height: 80vh;
    width: 60%;
    color: white;
    text-shadow: 1px 1px black;
}

/*Mobile scaling*/

@media screen and (max-width: 960px) {
    .impressum-container{
        width: 90%;
        margin-left: 7%;
    }
}
.datasecurity-container{
    display: block;
    margin: auto;
    height: 80vh;
    width: 60%;
    color: white;
    text-shadow: 1px 1px black;
    overflow-y: scroll;
    padding-right: 10px;
}

.source-link{
    color: white;
}

/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #00000000; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(126, 126, 126); 
}

/*Mobile scaling*/

@media screen and (max-width: 960px) {
    .datasecurity-container{
        margin-left: 8%;
        width: 85%;
    }
}
