/*All*/

@font-face {
  font-family: "DaysOne-Regular";
  src: url("./components/content/fonts/OpenSans-Regular.ttf");
}

body{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

#root{
  background-image: 
    linear-gradient(to bottom, rgb(255 255 255 / 0%), rgb(35 35 35 / 30%)),
    url("./components/content/images/contentImages/back.jpg"); 
  background-size: cover;
  height: 100%;
}

* {
  font-family: "DaysOne-Regular", Fallback, sans-serif;
  text-decoration: none;
}

/*Mobile scaling*/

@media screen and (max-width: 960px) {

  /*Overall*/

  body{
    height: 100%;
    overflow: visible;
  }

}