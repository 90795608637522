@keyframes blend-in {
  0.0%{
      bottom: -110%;
  }
  99.5%{
      bottom: 50px;
  }
}

.infobox{
  border-radius: 10px;
  position: absolute;
  bottom: 50px;
  right: 0;
  animation: blend-in .5s ease-out 0s 1 normal none;
}

.infomessage{
  margin: 25px;
  color: white;
}

.infobutton{
  position: absolute;
  top: 5px;
  left: 5px;
  width: auto;
  height: auto;
}

.infobutton *{
  filter: invert(100%);
  width: 15px;
  height: 15px;
}

.infobutton:hover{
  transition: ease 0.2s;
  filter: invert(60%);
  cursor: pointer;
}

@media screen and (max-width: 960px) {

  }